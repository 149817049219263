<template>
  <div id="app" class="app-container">
    <div class="header">
      <div class="logo">
        <img src="../assets/logo.png" />
      </div>
      <nav class="navbar">
        <ul class="nav-list">
          <li><a href="#download">软件下载</a></li>
        </ul>
      </nav>
    </div>
    <div class="content">
      <div class="icon">
        <img src="../assets/icon.png" alt="" />
      </div>
      <div class="main">
        <!-- <section id="home" class="hero">
          <div class="hero-content">
            <h2>欢迎来到莱嗨官网</h2>
          </div>
        </section> -->

        <!-- Features Section -->
        <section id="features" class="features">
          <h2>产品特点</h2>
          <div class="features-list">
            <div class="feature-card">
              <i class="fas fa-tasks feature-icon"></i>
              <h3>手柄游戏支持</h3>
              <p>轻松连接，畅玩无阻</p>
            </div>
            <div class="feature-card">
              <i class="fas fa-users feature-icon"></i>
              <h3>商城功能</h3>
              <p>游戏周边、虚拟道具、数字商品等，一应俱全</p>
            </div>
            <div class="feature-card">
              <i class="fas fa-mobile-alt feature-icon"></i>
              <h3>跨平台支持</h3>
              <p>支持 微信小程序、iOS、Android 多平台使用。</p>
            </div>
          </div>
        </section>

        <!-- Download Section -->
        <section id="download" class="download">
          <h2>立即下载莱嗨</h2>
          <div class="buttons">
            <button @click="download('android')" class="btn android">
              Android 版本下载
            </button>
            <button @click="download('ios')" class="btn ios">
              iOS 版本下载
            </button>
          </div>
        </section>

        <!-- Contact Section -->
        <div class="contact-container">
          <div class="contact-card">
            <h2>联系我们</h2>
            <p>如果您有任何问题，欢迎通过以下方式与我们联系：</p>
            <a class="contact-email" href="mailto:hzhongyikeji2@163.com">
              <span>Email: </span>hzhongyikeji2@163.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    download(platform) {
      let url = "";
      switch (platform) {
        case "windows":
          url = "https://example.com/download/windows"; // 替换为实际的 Windows 下载链接
          break;
        case "mac":
          url = "https://example.com/download/mac"; // 替换为实际的 Mac 下载链接
          break;
        case "android":
          url = "https://example.com/download/android"; // 替换为实际的 Android 下载链接
          break;
        case "ios":
          url = "https://example.com/download/ios"; // 替换为实际的 iOS 下载链接
          break;
      }
      window.location.href = url; // 跳转到相应平台的下载页面
    },
  },
};
</script>

<style scoped lang="scss">
.app-container {
  background: url("../assets/bg.png") no-repeat 100% 100%;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    justify-content: space-between;
    .logo {
      width: 68px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .navbar {
      display: flex;
      gap: 30px;
      .nav-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          display: inline;
          margin-right: 20px;
          a {
            color: #000000;
            font-size: 18px;
            font-weight: bold;
            transition: color 0.3s ease;
          }
          a:hover {
            color: #f95527;
          }
        }
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    height: 0;
    flex: 1;
    .icon {
      width: 804px;
      margin-left: -130px;
      img {
        width: 100%;
      }
    }
    .main {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .hero {
        color: rgb(0, 0, 0);
        padding: 0 40px;
        background-size: cover;
        text-align: right;
        .hero-content {
          text-align: right;
          h2 {
            font-size: 40px;
            font-weight: bold;
          }
        }
      }
      .features {
        padding: 60px 40px;
        h2 {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 40px;
          text-align: center;
        }
        .features-list {
          display: flex;
          justify-content: space-around;
          gap: 20px;
          flex-wrap: wrap;
        }
        .feature-card {
          background: rgba(224, 184, 184, 0.2);
          padding: 30px;
          border-radius: 10px;
          width: 280px;
          box-shadow: 0 4px 10px rgba(59, 59, 59, 0.1);
          text-align: center;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
        }
        .feature-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
        }
      }

      .feature-icon {
        font-size: 48px;
        color: #0078d4;
        margin-bottom: 20px;
      }

      .feature-card h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .feature-card p {
        font-size: 16px;
        color: #666;
      }
    }
  }
}

// /* Features Section */

// /* Download Section */
.download {
  text-align: center;
  padding: 80px 40px;
}

.download h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.btn {
  padding: 14px 30px;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.windows {
  background-color: #0078d4;
  color: white;
}

.mac {
  background-color: #333;
  color: white;
}

.android {
  background-color: #3ddc84;
  color: white;
}

.ios {
  background-color: #4f8df7;
  color: white;
}

.btn:hover {
  transform: scale(1.05);
}

/* Contact Section */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  .contact-card {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
  }

  h2 {
    font-size: 2rem;
    color: #2d3436;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.1rem;
    color: #636e72;
    margin-bottom: 20px;
  }

  .contact-email {
    display: inline-block;
    font-size: 1.2rem;
    color: #0984e3;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
  }

  .contact-email:hover {
    color: #d63031;
    border-bottom: 2px solid #d63031;
  }

  @media (max-width: 768px) {
    .contact-card {
      padding: 20px;
    }

    h2 {
      font-size: 1.6rem;
    }

    p {
      font-size: 1rem;
    }
  }
}
</style>
